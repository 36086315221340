import styles from "./Navbar.module.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import LogoMob from "../../assets/logo-mob.png";
import CVPDF from "../../assets/CV_Tonje.pdf";

const Navbar = ({ executeScroll }) => (
  <nav className={styles.wrapper}>
    <div className={styles.desktopWrapper}>
      <p className={styles.navItem} onClick={() => executeScroll("work")}>
        Work
      </p>
      <p className={styles.navItem} onClick={() => executeScroll("about")}>
        About
      </p>
      <div className={styles.logo}>
        <Logo />
      </div>
      <a href={CVPDF} download className={styles.navItem}>
        Resumé
      </a>
      <p className={styles.navItem} onClick={() => executeScroll("contact")}>
        Contact
      </p>

      <hr />
    </div>
    <div className={styles.mobWrapper}>
      <div className={styles.logoMob}>
        <img src={LogoMob} alt="tonje-logo" />
      </div>
      <div className={styles.mobContent}>
        <hr />
        <div className={styles.navItemsMob}>
          <p
            className={styles.navItemMob}
            onClick={() => executeScroll("work")}
          >
            Work
          </p>
          <p
            className={styles.navItemMob}
            onClick={() => executeScroll("about")}
          >
            About
          </p>
          <a href={CVPDF} download className={styles.navItemMob}>
            Resumé
          </a>{" "}
          <p
            className={styles.navItemMob}
            onClick={() => executeScroll("contact")}
          >
            Contact
          </p>
        </div>
      </div>
    </div>
  </nav>
);

export default Navbar;
