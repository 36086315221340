import TonjeImage from "./components/tonje-image/TonjeImage";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import styles from "./App.module.scss";
import Tonje from "./assets/tonje.jpg";
import Aragon from "./assets/aragon.png";
import Stall from "./assets/stall.png";
import ClimbingShoe from "./assets/climbingshoes.png";
import NatureDS from "./assets/nature-ds.png";
import NintendoDS from "./assets/nintendo-ds.png";
import Boulder from "./assets/boulder.jpg";
import Hike from "./assets/hike.png";
import Horse from "./assets/horse.png";
import Draw from "./assets/drawing.jpg";
import Forms from "./assets/form.png";
import { useRef } from "react";

function App() {
  const workRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const executeScroll = (refName) => {
    switch (refName) {
      case "work":
        workRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "contact":
        contactRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Navbar executeScroll={executeScroll} />
        <div className={styles.pages}>
          <div className={styles.page}>
            <div className={styles.section}>
              <TonjeImage src={Tonje} alt={"Tonje profilepic"} round />
              <div className={styles.dividerDiv}></div>
              <div className={styles.sectionDesc}>
                <h1>Hi, I'm Tonje</h1>
                <hr />
                <p className={styles.subTitle}>A UX-Designer in Oslo</p>
                <p>
                  I enjoy solving problems by researching and creating great
                  solutions with user experience as the main focus. Always
                  curious and eager to learn; I love talking to people and turn
                  ideas into plans.
                </p>
              </div>
            </div>

            <div className={styles.vl}></div>
            <h2 ref={workRef}>Work</h2>
            <div className={styles.section}>
              <div className={styles.sectionDesc}>
                <h3>Climbing Shoes Shop</h3>
                <p className={styles.subTitle}>Hobby project</p>
                <p>
                  A self project to better understand how a website presents
                  products and how to easely navigate and find information on
                  the different products using filters, list, etc.
                </p>
                <a class={styles.button} target="_blank" href="https://www.figma.com/file/26v7Pbnfelhw0HIkJwInza/Climbing-Shoes?node-id=0%3A1" rel="noreferrer">
                  View Case
                </a>
              </div>
              <div className={styles.dividerDiv}></div>
              <TonjeImage src={ClimbingShoe} />
            </div>

            <div className={styles.section}>
              <TonjeImage src={Stall} />
              <div className={styles.dividerDiv}></div>
              <div className={styles.sectionDesc}>
                <h3>Smith Stall Branding</h3>
                <p className={styles.subTitle}>Hobby project</p>
                <p>
                  A friend of mine requested ideas for what their brand could
                  be. I made a series of ideas to be elaborated.
                </p>
                <a class={styles.button} target="_blank" href="https://www.figma.com/file/2QsiDet6vRcRNQUdJA8nDm/Smith-Stall?node-id=0%3A1" rel="noreferrer">
                  View Case
                </a>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionDesc}>
                <h3>Nintendo Designsystem</h3>
                <p className={styles.subTitle}>School project</p>
                <p>
                  Tasked to pick a website we liked and create an unofficial
                  design system for the site. I picked nintendo’s official
                  website.
                </p>
                <a class={styles.button} target="_blank" href="https://www.figma.com/file/gmTf8CrVtezcKKVP5r0v4n/Design-System-Nintendo?node-id=0%3A1" rel="noreferrer">
                  View Case
                </a>
              </div>
              <div className={styles.dividerDiv}></div>
              <TonjeImage src={NintendoDS} />
            </div>

            <div className={styles.section}>
              <TonjeImage src={NatureDS} />
              <div className={styles.dividerDiv}></div>
              <div className={styles.sectionDesc}>
                <h3>Nature Designsystem</h3>
                <p className={styles.subTitle}>Hobby project</p>
                <p>
                  To be able to work with a designsystem is a neccessary skill
                  as a UX designer. I decide to create my own designsystem to
                  gain more experience.
                </p>
                <a class={styles.button} target="_blank" href="https://www.figma.com/file/t5EjLXrJAf4nWtrt9uOZlI/Nature-Design-System" rel="noreferrer">
                  View Case
                </a>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionDesc}>
                <h3>Forms</h3>
                <p className={styles.subTitle}>Hobby project</p>
                <p>
                  A task to make a “best practice” form and stages of a complete
                  form complete with helptext, etc.
                </p>
                <a class={styles.button} target="_blank" href="https://www.figma.com/file/OocnpeJwe7b1S6NBXMKkO1/Open-form-stages" rel="noreferrer">
                  View Case
                </a>
              </div>
              <div className={styles.dividerDiv}></div>
              <TonjeImage src={Forms} />
            </div>

            <div className={styles.vl}></div>
            <h2 ref={aboutRef}>About</h2>
            <div className={`${styles.section} ${styles.lastSection}`}>
              <div className={styles.sectionDesc}>
                <h3>About me</h3>
                <p className={styles.subTitle}></p>
                <p>
                  Besides design, I like to draw (traditional and digital). I
                  enjoy video games, books and movies. Being active is what I
                  always strive for, I love working out, riding horses or hiking
                  with my beautiful dog; Aragon.
                </p>
              </div>
              <div className={styles.dividerDiv}></div>
              <TonjeImage src={Aragon} />
            </div>
            <div className={styles.aboutImages}>
              <img src={Horse} alt="Horse"/>
              <img src={Hike} alt="Hike"/>
              <img src={Draw} alt="Draw"/>
              <img src={Boulder} alt="Boulder" />
            </div>
          </div>
        </div>
      </div>
      <div ref={contactRef}>
        <Footer />
      </div>
    </>
  );
}

export default App;
